import {service} from "node-back-client/src/services/api";

class ApiService {
    baseUrl = process.env.VUE_APP_BASE_URL;
    apiService

    constructor(resource) {
        if (!resource) throw new Error("Resource is not provided");
        this.apiService = service(`${this.baseUrl}`,resource);
        this.resource = resource;
    }

    postForm(url = "",data ={}) {
        return this.apiService.postForm(url,data);
    }

    get(id) {
        return this.apiService.get(id);
    }

    post(url="",data = {}) {
        return this.apiService.post(url,data);
    }

    delete(id) {
        return this.apiService.remove(id);
    }
}

export const api = {
    projects: new ApiService("projects"),
    servers: new ApiService("servers"),
    builders: new ApiService("builders"),
    commands: new ApiService("commands"),
    builds: new ApiService("builds"),
    deploys: new ApiService("deploys"),
};